import {
  Box,
  Wrap,
  Spacer,
  VStack,
  Flex,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useBoolean } from 'react-use';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import {
  AccessTokenType,
  refreshTokenTTLMins,
  useSignIn,
} from '../../AuthProvider';
import { RouterLink } from '../../ui-components/Link';
import { Button } from '../../ui-components/Button';
import { Alert } from '../../ui-components/Alert';
import { secondsToMinutes } from '../../timeHelpers';
import { isEmpty } from '../../functionHelpers';
import { Trans, useTranslation } from '../../i18n';
import { NetworkErrorAlert } from '../../ui-components/NetworkErrorAlert';
import { ApiErrorResponse, AxiosApiError } from '../../network/types';
import { grecaptchaKey } from '../../grecaptcha';
import { useResendActivationTokenMutation } from '../RegistrationVerifyEmailPage';

import { UserLoginFormValues } from './types';
import { LoginEmailField } from './LoginEmailField';
import { LoginPasswordField } from './LoginPasswordField';
import { useLoginMutation } from './useLoginMutation';

export type LoginFormProps = {};

const isInvalidCredentialsError = (error: unknown) => {
  return (
    axios.isAxiosError(error) &&
    (error?.response?.data as unknown as ApiErrorResponse).type ===
      'invalid_grant'
  );
};

const isInternalUserTryingToLoginAsExternalError = (error: AxiosApiError) => {
  return (
    axios.isAxiosError(error) &&
    (error?.response?.data as unknown as ApiErrorResponse).type ===
      'urn:problem-type:user:external-authenticate-not-allowed'
  );
};

export const LoginForm: FC<LoginFormProps> = () => {
  const { t, i18n } = useTranslation();

  const passwordFieldRef = useRef<HTMLInputElement>(null!);

  const signIn = useSignIn();

  const toast = useToast();

  const [signInError, setSignInError] = useBoolean(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const loginMutation = useLoginMutation({
    onSuccess: (data) => {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        expires_in: expirationTimeMs,
      } = data;

      const decodedAccessToken = jwtDecode<AccessTokenType>(accessToken);

      const handleSignInError = () => {
        setSignInError(true);
      };

      try {
        const expiresInMins = secondsToMinutes(expirationTimeMs);

        if (
          !signIn({
            expiresIn: expiresInMins,
            token: accessToken,
            tokenType: tokenType,
            refreshToken: refreshToken,
            refreshTokenExpireIn: refreshTokenTTLMins,
            authState: {
              userType: 'external',
              permissions: decodedAccessToken.scope,
            },
          })
        ) {
          handleSignInError();
          return;
        }
      } catch {
        handleSignInError();
      }
    },
  });

  const resendActivationTokenMutation = useResendActivationTokenMutation({
    onSuccess: () => {
      toast({
        title: t(
          'registrationVerifyEmail.successToastMsg',
          'Activation email sent successfully, check your inbox',
        ),
        status: 'success',
        isClosable: true,
      });
    },

    onError: (error) => {
      if (
        error.response?.data?.type ===
        'urn:problem-type:external-activation-not-allowed'
      ) {
        toast({
          title: t(
            'registrationVerifyEmail.externalActivationNotAllowed',
            'External activation by token not allowed for INTERNAL user',
          ),
          status: 'error',
          isClosable: true,
        });
      } else {
        toast({
          title:
            t(
              'registrationVerifyEmail.errorToastMsg',
              'Activation email failed to resend, try again…',
            ) +
            `\ntraceId: ${error.response?.data?.sentryTraceId}__${error.response?.data?.elkTraceId}`,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const formMethods = useForm<UserLoginFormValues>(
    process.env.NODE_ENV === 'development' ? {} : {},
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValidating, isSubmitted },
    reset,
    trigger,
    watch,
  } = formMethods;
  i18n.on('languageChanged', () => {
    reset();
  });
  const email = watch('email');
  const password = watch('password');

  useEffect(() => {
    if (trigger && isSubmitted) trigger();
  }, [t, trigger, email, password, isSubmitted]);

  useEffect(() => {
    if (isSubmitting || isValidating) {
      setSignInError(false);
    }
  }, [isSubmitting, setSignInError, isValidating]);

  useEffect(() => {
    if (loginMutation.error || signInError) {
      passwordFieldRef.current?.focus();
      recaptchaRef.current?.reset();
    }
  }, [loginMutation.error, signInError]);

  const onSubmit = async (data: UserLoginFormValues) => {
    const captchaCode = await recaptchaRef.current?.executeAsync();
    loginMutation.mutate({
      ...data,
      captchaCode: captchaCode || '',
    });
  };

  const renderForgotPasswordLink = () => (
    <RouterLink to="/forgot-password">
      {t('login.forgotPasswordLabel', 'Forgot your password?')}
    </RouterLink>
  );

  const renderUserLoginError = () => {
    if (!loginMutation.isError && !signInError) {
      return null;
    }

    if (isInvalidCredentialsError(loginMutation.error!)) {
      return (
        <Alert status="error">
          <AlertIcon />
          {t(
            'login.invalidCredentials',
            'No user found for this email/password',
          )}
        </Alert>
      );
    }

    if (isInternalUserTryingToLoginAsExternalError(loginMutation.error!)) {
      return (
        <Alert status="error">
          <AlertIcon />
          {t(
            'login.internalUserTryingToLoginAsExternal',
            'A user with this email already exists in the MyWorld system, please log in using MyWorld',
          )}
        </Alert>
      );
    }

    if (
      (loginMutation.error?.response?.data as unknown as ApiErrorResponse)
        .type ===
      'urn:problem-type:user:email-verification-required-user-authenticate-not-allowed'
    ) {
      return (
        <NetworkErrorAlert networkError={loginMutation.error}>
          <Trans i18nKey="resendLink.userExistsWithEmailDuringLogin">
            Account associated with specified email address has not completed
            the registration process (email verification is required). If you
            did not receive verification email click
            <Button
              as={Link}
              variant="link"
              onClick={() => resendActivationTokenMutation.mutate({ email })}
            >
              here
            </Button>{' '}
            to receive new verification email and use it to complete
            registration process
          </Trans>
        </NetworkErrorAlert>
      );
    }

    return (
      <NetworkErrorAlert networkError={loginMutation.error}>
        {t('login.generalError', 'Something went wrong, try again')}
      </NetworkErrorAlert>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <DevTool control={control} />
      <Box as="form" onSubmit={handleSubmit(onSubmit)} width="full">
        <VStack alignItems="stretch" spacing={6}>
          <Flex
            as="fieldset"
            direction="column"
            disabled={loginMutation.isLoading}
          >
            <LoginEmailField autoFocus />

            <LoginPasswordField inputRef={passwordFieldRef} />
          </Flex>

          {renderUserLoginError()}

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={grecaptchaKey}
            size="invisible"
            onExpired={() => {
              recaptchaRef.current?.reset();
            }}
          />

          <VStack alignItems="stretch">
            {/* TODO: add `WrapItem`'s */}
            <Wrap>
              <Spacer />
              {renderForgotPasswordLink()}
            </Wrap>
            <Button
              type="submit"
              width="full"
              isLoading={loginMutation.isLoading}
              disabled={!isEmpty(errors)}
            >
              {t('login.submit', 'CONTINUE')}
            </Button>
          </VStack>
        </VStack>
      </Box>
    </FormProvider>
  );
};
